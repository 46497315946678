import { getHttpClient } from "@utils/httpClient";

/**
 * This function is async to be prepared to load the CSRF token via fetch later.
 */
export function getCsrfToken(): Promise<string> {
  return new Promise((resolve) => resolve(document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? ""));
}

function updateCsrfToken(token: string): void {
  let tokenMeta = document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement | null;
  if (tokenMeta === null) {
    tokenMeta = new HTMLMetaElement();
    document.head.append(tokenMeta);
  }
  tokenMeta.content = token;
}

/*
 * Function to install an interval timer that performs an ajax call on every tick,
 * thus keeping the user's HTTP session alive as long as the browser window is open.
 * That allows for a shorter session timeout time on the server, which saves resources.
 * The procedure stops either after options.maxCount cycles or as soon as the server responds with an empty response (instead of a valid CSRF token).
 * Options:
 *  - url: the URL for the ajax call (default: /session-keepalive)
 *  - interval: duration in ms between ajax calls (default: 540000 = 9min)
 *  - maxCount: stop the keep alive after n ticks. maxCount * interval effectively gives a max session timeout. (Default: 160)
 */
type KeepaliveOptions = {
  url?: string;
  interval?: number;
  maxCount?: number;
};

export function setupSessionKeepalive(): void {
  // Init variables
  let handle: number | undefined = undefined;
  let counter = 0;
  const customOptionsString = document.querySelector<HTMLElement>("[data-ga-session-keepalive-options]")?.dataset["gaSessionKeepaliveOptions"] ?? null;
  const customOptions = customOptionsString !== null ? (JSON.parse(customOptionsString) as KeepaliveOptions) : {};
  const settings = {
    ...{
      url: "/session-keepalive",
      interval: 540000, // 9min (session timeout is 10min)
      maxCount: 160, // 160 * 9min = 24h
    },
    ...customOptions,
  };
  // Even if the session timed out in the meantime, this helps us to at least recover the sessions UUID for tracking reasons
  const sessionUuidElement = document.querySelector<HTMLElement>("[data-ga-recover]");
  const sessionUuid = sessionUuidElement?.dataset["gaRecover"] ?? null;

  // The repeating function
  const keepAlive = async function (): Promise<void> {
    const response = await getHttpClient().get(settings.url, sessionUuid != null ? { headers: { uuid: sessionUuid } } : undefined);
    const csrfToken = response.data as string;
    if (csrfToken === "" || ++counter >= settings.maxCount) {
      // Let the server stop the loop with an empty response body, or after 100x10min = 16h
      window.clearInterval(handle);
    } else {
      updateCsrfToken(csrfToken);
    }
  };

  // Start the keep alive timer
  handle = window.setInterval(keepAlive, settings.interval);
}
